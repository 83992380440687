<template>
  <div>
    <VatInvoicePreview v-if="$route.params.type === 'Invoice_Vat'" :disabled=true />
    <AdvancePayment v-if="$route.params.type === 'Invoice_Prepayment'" :disabled=true />
    <Proforma v-if="$route.params.type === 'Invoice_Proforma'" :disabled=true />
    <BankStatement
      v-if="$route.params.type === 'bank-statement'"
      :preview="true"
    />
  </div>
</template>

<script>
import {
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AdvancePayment from '@/views/apps/invoice/invoice-types/AdvancePayment.vue'
import VatInvoicePreview from '@/views/apps/invoice/invoice-preview/VatInvoicePreview.vue'
import BankStatement from '../invoice-types/BankStatement.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    VatInvoicePreview,
    AdvancePayment,
    BankStatement,
  },
  data() {
    return {
      invoice: null,
      invoiceNumber: null,
      invoiceReleaseDate: null,
      nettoValue: 0,
      nettoAfterDiscount: 0,
      taxValue: 0,
      bruttoValue: 0,
      discount: 0,
      debtHistory: [],
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
